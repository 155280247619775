import { type PlayerItemResponse } from '@on3/api';
import { SportIcon, SportName } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { TeamAvatar } from 'components/Image/TeamAvatar';
import { divisionAbbreviation } from 'components/Nil/helpers';
import { ArrowRight } from 'components/Svg/ArrowRight';
import { AthleteVerified } from 'components/UI/AthleteVerified/AthleteVerified';
import { TextLink } from 'components/UI/Link/TextLink';
import { StarRatings } from 'components/UI/StarRatings/StarRatings';
import { format } from 'date-fns';
import Link from 'next/link';

import styles from './LatestCommits.module.scss';

export const LatestPlayer = ({ data }: { data: PlayerItemResponse }) => {
  const { rating, status, player, transferRating, rosterRating } = data || {};
  const {
    slug,
    height,
    defaultAsset,
    weight,
    fullName,
    athleteVerified,
    position,
    division,
    defaultSport,
  } = player ?? {};

  const activeRating = transferRating || rosterRating || rating;
  const sportName = (rating?.sport?.name || defaultSport?.name) as SportName;

  return (
    <li className={styles.latestPlayerContainer}>
      <TextLink className={styles.avatarContainer} href={`/db/${slug}/`}>
        <On3Avatar
          altText={`${fullName} Avatar`}
          className={styles.playerAvatar}
          domain={defaultAsset?.domain}
          height={50}
          source={defaultAsset?.source}
          width={50}
        />
      </TextLink>

      <div className={styles.infoWrapper}>
        <div className={styles.sportsWrapper}>
          {division && <span>{divisionAbbreviation(division)}</span>}
          <SportIcon className={styles.sportIcon} sportName={sportName} />
          <span>{position?.abbr}</span>
        </div>
        {fullName && (
          <div className={styles.playerName}>
            <TextLink
              className={styles.playerLink}
              color="primary"
              href={`/db/${slug}/`}
              underline="none"
            >
              {fullName}
            </TextLink>
            {athleteVerified && <AthleteVerified />}
          </div>
        )}
        <div className={styles.vitalWrapper}>
          <div aria-label="Player Vitals" className={styles.playerVitals}>
            <span>{position?.abbr ?? '-'}</span>
            <span className={styles.divide} role="separator">
              /
            </span>
            <span>{height ?? '-'}</span>
            <span className={styles.divide} role="separator">
              /
            </span>
            <span>{weight ?? '-'}</span>
          </div>
          <div className={styles.playerStarRating}>
            <Link href={`/db/${slug}/industry-comparison/`}>
              <StarRatings stars={activeRating?.stars ?? 0} />
            </Link>
            {activeRating?.rating && (
              <span>{activeRating.rating.toFixed(2)}</span>
            )}
          </div>
        </div>
        <div className={styles.teamContainer}>
          <span>{status?.date && format(new Date(status.date), 'P')}</span>
          {status?.transferredAsset?.assetUrl && (
            <>
              <TeamAvatar
                className={styles.image}
                height={20}
                src={status.transferredAsset.assetUrl}
                width={20}
              />
              <ArrowRight className={styles.arrow} />
            </>
          )}
          {status?.committedAsset?.assetUrl && (
            <TeamAvatar
              className={styles.image}
              height={20}
              src={status.committedAsset.assetUrl}
              width={20}
            />
          )}
        </div>
      </div>
    </li>
  );
};
