import { On3IconAthleteVerified } from '@on3/icons';
import { TextLink } from 'components/UI/Link/TextLink';

interface IAthleteVerifiedProps {
  className?: string;
}
export const AthleteVerified = ({ className }: IAthleteVerifiedProps) => {
  return (
    <TextLink className={className} href="/elite/claim/">
      <On3IconAthleteVerified />
    </TextLink>
  );
};
